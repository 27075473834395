import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import * as styles from './article-preview.module.css'

const ArticlePreview = ({ article }) => (
  <div className={styles.singleArticle}>
    <div className={styles.previewImg}>
      <Img alt="article hero image" fluid={article.heroImage.fluid} />
    </div>
    <div />
    <div className={styles.singleArticleContent}>
      <div className={styles.singleArticleMainContent}>
        <small className={styles.publishDate}>{article.publishDate}</small>
        <h3 className={styles.previewTitle}>
          <Link to={`/highlights/${article.slug}`}>{article.title}</Link>
        </h3>
        <p
          dangerouslySetInnerHTML={{
            __html: article.description.childMarkdownRemark.html
          }}
        />
      </div>
      {/* <div className={styles.articleTags}>
        <a href="#">NaijaCore</a> | <a href="#">Abuja</a> |{' '}
        <a href="#">Youth Fitness</a>
      </div> */}
    </div>
  </div>
)

export default ArticlePreview
