import React from 'react'
import InputBase from '@material-ui/core/InputBase'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import * as styles from './article-search.module.css'

// remove theme argument from makeStyles()
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  search: {
    position: 'relative',
    // borderRadius: theme.shape.borderRadius,
    borderRadius: '4px',
    backgroundColor: '#fff',
    marginLeft: 0,
    // width: '100%',
    // use padding 2px to adjust search input height
    padding: '2px',
    // [theme.breakpoints.up('sm')]: {
    //   // marginLeft: theme.spacing(1),
    //   marginLeft: '.05rem',
    //   width: '10rem',
    // },
    marginLeft: '.05rem',
    width: '10rem',
  },
  searchIcon: {
    // padding: theme.spacing(1, 1),
    padding: '0.5rem 0.5rem',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#ef4b40',
  },
  inputRoot: {
    // color: '#ef4b40',
    fontSize: '0.75rem',
  },
  inputInput: {
    // padding: theme.spacing(1, 1, 1, 0),
    paddingTop: "11px",
    paddingBottom: "11px",
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    paddingLeft: `calc(1em + 32px)`,
    // transition: theme.transitions.create('width'),
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   width: '12ch',
    // },

  },
  formControl: {
    // margin: theme.spacing(1),
    margin: '0.5rem',
    minWidth: "10rem",
    height: "2rem",
    justifyContent: "center",
  },
  selectEmpty: {
    // marginTop: theme.spacing(1),
    marginTop: '0.5rem',
  },
  selectControl: {
    backgroundColor: "white",
  },
  selectLabelControl: {
    fontSize: "0.75rem",
  },
}));

export default function ArticleSearch() {
  const materialClasses = useStyles()
  const [category, setCategory] = React.useState('')

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  return (
    <div className={styles.highlightsFilter}>
      <div className={materialClasses.search}>
        <div className={materialClasses.searchIcon}>
          <SearchIcon className={styles.searchIcon} />
        </div>
        <InputBase
          variant="contained"
          placeholder="Search…"
          classes={{
            root: materialClasses.inputRoot,
            input: materialClasses.inputInput
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
      </div>

        <FormControl size="small" variant="outlined" className={materialClasses.formControl}>
        <InputLabel id="demo-simple-select-outlined-label" className={materialClasses.selectLabelControl}>All Categories...</InputLabel>
        <Select
          className={materialClasses.selectControl}
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={category}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>NaijaCore</MenuItem>
          <MenuItem value={20}>Abuja</MenuItem>
          <MenuItem value={30}>Youth Fitness</MenuItem>
        </Select>
      </FormControl>
      <button className={styles.highlightsFilterbutton}>FILTER POSTS</button>
    </div>
  )
}
